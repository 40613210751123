import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './ModalAvail.css';

// import Modal from 'react-bootstrap/Modal';
// import ListGroup from 'react-bootstrap/ListGroup';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Carousel from 'react-bootstrap/Carousel';
// import Magnifier from 'react-magnifier';

const ModalAvail = (props) => {
  // const [modalShow, setModalShow] = useState(false);

  // const handleClose = () => setModalShow(false);
  // const handleShow = () => setModalShow(true);
  return (
    <>
      {/* <div className="btn_available_box" onClick={handleShow}>
        <div className="btn_available">Product Data</div>
      </div> */}

      <Dropdown className="btn_available_box">
        <Dropdown.Toggle as="div" variant="" className="btn_available">
          Product Data
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          {props.items.map((product) => (
            <Dropdown.Item key={product.id} href={product.link}>
              <b>{product.series}</b> {product.product}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* <Modal
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
        dialogClassName="hero-modal-width"
        contentClassName="hero-modal-height"
        centered
      > */}
      {/* <Modal.Header closeButton /> */}

      {/* <Carousel
            className="product_carousel"
            indicators={props.indicators}
            controls={props.controls}
            variant="dark"
          >
            {props.availImages.map((avail) => (
              <Carousel.Item key={avail.id}>
                <Magnifier
                  src={avail.image}
                  alt={avail.alt}
                  zoomFactor={1}
                  mgShape={'square'}
                  mgHeight={300}
                  mgWidth={400}
                />
              </Carousel.Item>
            ))}
          </Carousel> */}
      {/* </Modal> */}
    </>
  );
};

export default ModalAvail;
