const boards = [
  {
    id: 1,
    series: '1000 SERIES',
    product: 'Blade Tray C-4 Trim',
    link: 'https://drive.google.com/file/d/1_oJqqwg1iDURNymj068QXa6Dw16MGr2U/view',
  },
  {
    id: 2,
    series: '1500 SERIES',
    product: 'Blade Tray C-18 Trim',
    link: 'https://drive.google.com/file/d/17RC8DLjHhcd04HzzW5at0AxxUAEZw3rG/view',
  },
  {
    id: 3,
    series: '2000 SERIES',
    product: 'Box Tray C-4 Trim',
    link: 'https://drive.google.com/file/d/1uzaCgY0-LRblNfYKmAjlqa6bRDNCdoZE/view',
  },
  {
    id: 4,
    series: '2500 SERIES',
    product: 'Box Tray C-18 Trim',
    link: 'https://drive.google.com/file/d/1ZaixFcNTu9SkvizHgeNX6fIoTZez69DY/view',
  },
  {
    id: 5,
    series: '3000 SERIES',
    product: 'Premium Box Tray C-4 Trim',
    link: 'https://drive.google.com/file/d/1m4hZnMAgqlIt0exdiaBP9kjOrjq5E5Pr/view',
  },
  {
    id: 6,
    series: '3500 SERIES',
    product: 'Premium Box Tray C-18 Trim',
    link: 'https://drive.google.com/file/d/1LNV4OldRzv-I2j9_f7cgygu1VPyCpwYC/view',
  },
];

const slider = [
  {
    id: 1,
    series: 'HSS SERIES',
    product: 'Horizontal Slider Markerboard',
    link: 'https://drive.google.com/file/d/1za_5RePVLBmggDEI-yH5ELSTgdoHjZ86/view',
  },
];

const verticalSlider = [{
  id:1,
  series:'VFM & VWM Series',
  product:"Vertical Sliding Markerboards",
  link:"https://drive.google.com/file/d/1ybagIMH7KwfGGGcj76x8eWAxP-y5d7l9/view?usp=sharing"
}]

const enclosedBB = [{
  id:1,
  series:'HBC Series',
  product:"Hinged Bulletin Case",
  link:"https://drive.google.com/file/d/1P1r4YgWr8JkxH_bF9mj4TZ3S3XMH3SoJ/view"
}]


const inWallTables = [
  {
    id: 1,
    product: 'DRM-3TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1UKwvObHUOPROe3-pdUKY7Vrv2VQ4fJaI/view',
  },
  {
    id: 2,
    product: 'DRM-6TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1jkybMdd24fFx2pqWmF6wQj_PcwZRDyUv/view',
  },
  {
    id: 3,
    product: 'DRM-9TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1MvX7hUHuuru8cHl67KsOYRHrfvXg9sps/view',
  },
  {
    id: 4,
    product: 'DRM-12TB',
    series: 'Recessed',
    link: 'https://drive.google.com/file/d/1nZ01VfxtJNt6LYOqdRcurGRUJkLvDNAq/view',
  },
  {
    id: 5,
    product: 'DSM-3TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/1gJPmCXWNshHLUxBDhDRRrJv5Nc_2kwy4/view',
  },
  {
    id: 6,
    product: 'DSM-6TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/1MSOWSRpr6ClSraBCC_3yzxxuG6Y4fGrS/view',
  },
  {
    id: 7,
    product: 'DSM-9TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/16IY7GiSs_ogKSA2HlgKzh4WMeiLvPzWQ/view',
  },
  {
    id: 8,
    product: 'DSM-12TB',
    series: 'Surfaced',
    link: 'https://drive.google.com/file/d/1jZjFOn26Ei8uxOYqmKi3CjpO5I40RPmC/view',
  },
];

const teachingWall = [
  {
    id: 1,
    series: 'TWS SERIES',
    product: 'Teaching Wall Slider',
    link: 'https://drive.google.com/file/d/16i00Z9cuIvcZ1iEpA84Ag94znjM66Ox2/view',
  },
];

const hindgedDC = [
  {
    id: 1,
    series: 'HDC Series',
    product: 'Hinged Display case',
    link: 'https://drive.google.com/file/d/1JZMGVdbN1VmB4WzXxVfOFDSxnO7iLrb8/view',
  },
];

const slidingDC = [
  {
    id: 1,
    series: 'SDC Series',
    product: 'Sliding Display Case',
    link: 'https://drive.google.com/file/d/1v23WxhyJlmUg_owO_2mQeivsjr2u2ysC/view',
  },
];

const forboWall = [
  {
    id: 1,
    series: 'FDW Series',
    product: 'Forbo Directly to Wall',
    link: 'https://drive.google.com/file/d/1vdmR1wQAc-mHlgjKx15mEIcLngm1P4Fy/view',
  },
];

const tackWall = [
  {
    id: 1,
    series: 'TWP Series',
    product: 'Tack Wall Panels',
    link: 'https://drive.google.com/file/d/1Dpveq-qa5f85NIqnycm-TEuKrECW9L7L/view',
  },
];

const markerWall = [
  {
    id: 1,
    series: 'MW Series',
    product: 'Marker Wall',
    link: 'https://drive.google.com/file/d/1UWrMzqXtrbce9iTRBXBbJko_4XSQzmj8/view',
  },
];

export {
  boards,
  slider,
  verticalSlider,
  enclosedBB,
  inWallTables,
  teachingWall,
  hindgedDC,
  slidingDC,
  forboWall,
  tackWall,
  markerWall,
};
