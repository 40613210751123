import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import escribanoWalk from '../../assets/images/homepage/escribano_walk1.jpg';
// import escribano from '../../assets/images/homepage/escribano.png';
import visual from '../../assets/images/homepage/visualdisplay.jpg';
import table from '../../assets/images/homepage/tables.jpg';
import tackwall from '../../assets/images/homepage/tackwall.jpg';

import styles from './HeroHover.module.css';

const Slideshow = () => {
  const [bgImage, setbgImage] = useState(visual);

  // const changeToEscribano = () => {
  //   setbgImage(escribano);
  // };

  const changeToVisualDisplay = () => {
    setbgImage(visual);
  };

  const changeToTables = () => {
    setbgImage(table);
  };

  const changeToTackwall = () => {
    setbgImage(tackwall);
  };

  const changeBackToDefault = () => {
    setbgImage(visual);
  };

  return (
    <section className={styles.hero_section}>
      <div
        className={styles.background}
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
      ></div>
      <div className={styles.product_titles_box}>
        <div className={styles.products_title_line1}>
          {/* <Link to="/escribano">
            <div
              className={`${styles.btn} ${styles.btn_escribano}`}
              onMouseEnter={changeToEscribano}
              onMouseLeave={changeBackToDefault}
            >
              | Escribano |
            </div>
          </Link> */}
          <Link to="/visual">
            <div
              className={`${styles.btn} ${styles.btn_visual}`}
              onMouseEnter={changeToVisualDisplay}
              onMouseLeave={changeBackToDefault}
            >
              &nbsp;| Visual Displays |
            </div>
          </Link>
        </div>
        <div className={styles.products_title_line2}>
          <Link to="/in-wall-tables">
            <div
              className={`${styles.btn} ${styles.btn_tables}`}
              onMouseEnter={changeToTables}
              onMouseLeave={changeBackToDefault}
            >
              | In-Wall Table&nbsp;
            </div>
          </Link>
          <Link to="/wall-systems">
            <div
              className={`${styles.btn} ${styles.btn_tackwall}`}
              onMouseEnter={changeToTackwall}
              onMouseLeave={changeBackToDefault}
            >
              | Wall Systems |
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Slideshow;
