const project = [
  {
    id: 1,
    name: 'Sayra Mojica',
    title: 'DIRECTOR OF PROJECT MANAGEMENT',
    email: 'sayra@nelsonadamsnaco.com',
    ext: 'Ext. 115',
  },
  {
    id: 2,
    name: 'Magali Santos',
    title: 'Project Manager',
    email: 'magali@nelsonadamsnaco.com',
    ext: 'Ext. 111',
  },
  {
    id: 3,
    name: 'Grecia Ocampo',
    title: 'Project Manager',
    email: 'grecia@nelsonadamsnaco.com',
    ext: 'Ext. 201',
  },
  {
    id: 4,
    name: 'Hector Millan',
    title: 'Project Manager',
    email: 'Hector@nelsonadamsnaco.com',
    ext: 'Ext. 117',
  },
  {
    id: 5,
    name: 'Nancy Santos',
    title: 'Project Manager',
    email: 'Nancy@nelsonadamsnaco.com',
    ext: 'Ext. 110',
  },
];

const estimating = [
  {
    id: 1,
    name: 'Alma Rodriguez',
    title: 'Sales Manager',
    email: 'Sales@nelsonadamsnaco.com',
    ext: 'Ext. 112',
  },
  {
    id: 2,
    name: 'Bedsaida Osuna',
    title: 'Sales Represetative',
    email: 'Bedsi@nelsonadamsnaco.com',
    ext: 'Ext. 102',
  },
  {
    id: 3,
    name: 'Brenda ',
    title: 'Contract Negotiator',
    email: 'ContractNegotiator@nelsonadamsnaco.com',
    ext: 'EXT. 114'
  },
  {
    id: 4,
    name: 'Abigail Santos',
    title: 'Estimator',
    email: 'Abigail@nelsonadamsnaco.com',
    ext: 'Ext. 119',
  },
  {
    id: 5,
    name: 'Melanie Mojica',
    title: 'Filtering',
    email: 'estimating@nelsonadamsnaco.com',
    ext: 'Ext. 116',
  },
];

const legal = [
  {
    id: 1,
    name: 'Carlos Torres',
    title: 'Legal',
    email: 'LegalDepartment@nelsonadamsnaco.com',
    ext: 'Ext. 106',
  },
  {
    id: 2,
    name: 'Rosi Barcernas',
    title: 'Legal',
    email: 'Rosi@nelsonadamsnaco.com',
    ext: 'Ext. 105',
  },
];
const executive = [
  {
    id: 1,
    name: 'Rafael Rangel',
    title: 'President',
    email: 'rafaelrangel@nelsonadamsnaco.com',
  },
  {
    id: 2,
    name: 'Patricia Flores',
    title: 'General Manager',
    email: 'pflores@nelsonadamsnaco.com',
  },
];

export { project, estimating, legal, executive };
